import * as a from "react";
import Z from "react";
import { j as f } from "./jsx-runtime-B6kdoens.js";
import * as nr from "react-dom";
import rr from "react-dom";
import { u as or, a as ar, o as sr, s as cr, f as ir, b as ur, c as lr, h as dr, l as fr } from "./floating-ui.react-dom-vb4skoDG.js";
import { c as pr, u as mr, f as vr, z as hr, s as gr, R as wr, e as xr, h as Cr } from "./component-BNzimWXw.js";
import { _ as br, a as oe, b as yr } from "./tslib.es6-CR4a2p76.js";
import { c as G } from "./utils-CJ9afRe1.js";
import { I as Ue } from "./Icon-C5a5ARPP.js";
function M(e, t, { checkForDefaultPrevented: n = !0 } = {}) {
  return function(o) {
    if (e == null || e(o), n === !1 || !o.defaultPrevented)
      return t == null ? void 0 : t(o);
  };
}
function Er(e, t) {
  typeof e == "function" ? e(t) : e != null && (e.current = t);
}
function Re(...e) {
  return (t) => e.forEach((n) => Er(n, t));
}
function D(...e) {
  return a.useCallback(Re(...e), e);
}
function pt(e, t = []) {
  let n = [];
  function r(s, i) {
    const c = a.createContext(i), l = n.length;
    n = [...n, i];
    const u = (d) => {
      var x;
      const { scope: m, children: g, ...h } = d, p = ((x = m == null ? void 0 : m[e]) == null ? void 0 : x[l]) || c, w = a.useMemo(() => h, Object.values(h));
      return /* @__PURE__ */ f.jsx(p.Provider, { value: w, children: g });
    };
    u.displayName = s + "Provider";
    function v(d, m) {
      var p;
      const g = ((p = m == null ? void 0 : m[e]) == null ? void 0 : p[l]) || c, h = a.useContext(g);
      if (h) return h;
      if (i !== void 0) return i;
      throw new Error(`\`${d}\` must be used within \`${s}\``);
    }
    return [u, v];
  }
  const o = () => {
    const s = n.map((i) => a.createContext(i));
    return function(c) {
      const l = (c == null ? void 0 : c[e]) || s;
      return a.useMemo(
        () => ({ [`__scope${e}`]: { ...c, [e]: l } }),
        [c, l]
      );
    };
  };
  return o.scopeName = e, [r, Sr(o, ...t)];
}
function Sr(...e) {
  const t = e[0];
  if (e.length === 1) return t;
  const n = () => {
    const r = e.map((o) => ({
      useScope: o(),
      scopeName: o.scopeName
    }));
    return function(s) {
      const i = r.reduce((c, { useScope: l, scopeName: u }) => {
        const d = l(s)[`__scope${u}`];
        return { ...c, ...d };
      }, {});
      return a.useMemo(() => ({ [`__scope${t.scopeName}`]: i }), [i]);
    };
  };
  return n.scopeName = t.scopeName, n;
}
function $(e) {
  const t = a.useRef(e);
  return a.useEffect(() => {
    t.current = e;
  }), a.useMemo(() => (...n) => {
    var r;
    return (r = t.current) == null ? void 0 : r.call(t, ...n);
  }, []);
}
function Be({
  prop: e,
  defaultProp: t,
  onChange: n = () => {
  }
}) {
  const [r, o] = Mr({ defaultProp: t, onChange: n }), s = e !== void 0, i = s ? e : r, c = $(n), l = a.useCallback(
    (u) => {
      if (s) {
        const d = typeof u == "function" ? u(e) : u;
        d !== e && c(d);
      } else
        o(u);
    },
    [s, e, o, c]
  );
  return [i, l];
}
function Mr({
  defaultProp: e,
  onChange: t
}) {
  const n = a.useState(e), [r] = n, o = a.useRef(r), s = $(t);
  return a.useEffect(() => {
    o.current !== r && (s(r), o.current = r);
  }, [r, o, s]), n;
}
var de = a.forwardRef((e, t) => {
  const { children: n, ...r } = e, o = a.Children.toArray(n), s = o.find(Pr);
  if (s) {
    const i = s.props.children, c = o.map((l) => l === s ? a.Children.count(i) > 1 ? a.Children.only(null) : a.isValidElement(i) ? i.props.children : null : l);
    return /* @__PURE__ */ f.jsx(je, { ...r, ref: t, children: a.isValidElement(i) ? a.cloneElement(i, void 0, c) : null });
  }
  return /* @__PURE__ */ f.jsx(je, { ...r, ref: t, children: n });
});
de.displayName = "Slot";
var je = a.forwardRef((e, t) => {
  const { children: n, ...r } = e;
  if (a.isValidElement(n)) {
    const o = Nr(n);
    return a.cloneElement(n, {
      ..._r(r, n.props),
      // @ts-ignore
      ref: t ? Re(t, o) : o
    });
  }
  return a.Children.count(n) > 1 ? a.Children.only(null) : null;
});
je.displayName = "SlotClone";
var Rr = ({ children: e }) => /* @__PURE__ */ f.jsx(f.Fragment, { children: e });
function Pr(e) {
  return a.isValidElement(e) && e.type === Rr;
}
function _r(e, t) {
  const n = { ...t };
  for (const r in t) {
    const o = e[r], s = t[r];
    /^on[A-Z]/.test(r) ? o && s ? n[r] = (...c) => {
      s(...c), o(...c);
    } : o && (n[r] = o) : r === "style" ? n[r] = { ...o, ...s } : r === "className" && (n[r] = [o, s].filter(Boolean).join(" "));
  }
  return { ...e, ...n };
}
function Nr(e) {
  var r, o;
  let t = (r = Object.getOwnPropertyDescriptor(e.props, "ref")) == null ? void 0 : r.get, n = t && "isReactWarning" in t && t.isReactWarning;
  return n ? e.ref : (t = (o = Object.getOwnPropertyDescriptor(e, "ref")) == null ? void 0 : o.get, n = t && "isReactWarning" in t && t.isReactWarning, n ? e.props.ref : e.props.ref || e.ref);
}
var Ir = [
  "a",
  "button",
  "div",
  "form",
  "h2",
  "h3",
  "img",
  "input",
  "label",
  "li",
  "nav",
  "ol",
  "p",
  "span",
  "svg",
  "ul"
], A = Ir.reduce((e, t) => {
  const n = a.forwardRef((r, o) => {
    const { asChild: s, ...i } = r, c = s ? de : t;
    return typeof window < "u" && (window[Symbol.for("radix-ui")] = !0), /* @__PURE__ */ f.jsx(c, { ...i, ref: o });
  });
  return n.displayName = `Primitive.${t}`, { ...e, [t]: n };
}, {});
function mt(e, t) {
  e && nr.flushSync(() => e.dispatchEvent(t));
}
function Ar(e, t = []) {
  let n = [];
  function r(s, i) {
    const c = a.createContext(i), l = n.length;
    n = [...n, i];
    function u(d) {
      const { scope: m, children: g, ...h } = d, p = (m == null ? void 0 : m[e][l]) || c, w = a.useMemo(() => h, Object.values(h));
      return /* @__PURE__ */ f.jsx(p.Provider, { value: w, children: g });
    }
    function v(d, m) {
      const g = (m == null ? void 0 : m[e][l]) || c, h = a.useContext(g);
      if (h) return h;
      if (i !== void 0) return i;
      throw new Error(`\`${d}\` must be used within \`${s}\``);
    }
    return u.displayName = s + "Provider", [u, v];
  }
  const o = () => {
    const s = n.map((i) => a.createContext(i));
    return function(c) {
      const l = (c == null ? void 0 : c[e]) || s;
      return a.useMemo(
        () => ({ [`__scope${e}`]: { ...c, [e]: l } }),
        [c, l]
      );
    };
  };
  return o.scopeName = e, [r, Tr(o, ...t)];
}
function Tr(...e) {
  const t = e[0];
  if (e.length === 1) return t;
  const n = () => {
    const r = e.map((o) => ({
      useScope: o(),
      scopeName: o.scopeName
    }));
    return function(s) {
      const i = r.reduce((c, { useScope: l, scopeName: u }) => {
        const d = l(s)[`__scope${u}`];
        return { ...c, ...d };
      }, {});
      return a.useMemo(() => ({ [`__scope${t.scopeName}`]: i }), [i]);
    };
  };
  return n.scopeName = t.scopeName, n;
}
function vt(e) {
  const t = e + "CollectionProvider", [n, r] = Ar(t), [o, s] = n(
    t,
    { collectionRef: { current: null }, itemMap: /* @__PURE__ */ new Map() }
  ), i = (g) => {
    const { scope: h, children: p } = g, w = Z.useRef(null), x = Z.useRef(/* @__PURE__ */ new Map()).current;
    return /* @__PURE__ */ f.jsx(o, { scope: h, itemMap: x, collectionRef: w, children: p });
  };
  i.displayName = t;
  const c = e + "CollectionSlot", l = Z.forwardRef(
    (g, h) => {
      const { scope: p, children: w } = g, x = s(c, p), C = D(h, x.collectionRef);
      return /* @__PURE__ */ f.jsx(de, { ref: C, children: w });
    }
  );
  l.displayName = c;
  const u = e + "CollectionItemSlot", v = "data-radix-collection-item", d = Z.forwardRef(
    (g, h) => {
      const { scope: p, children: w, ...x } = g, C = Z.useRef(null), y = D(h, C), E = s(u, p);
      return Z.useEffect(() => (E.itemMap.set(C, { ref: C, ...x }), () => void E.itemMap.delete(C))), /* @__PURE__ */ f.jsx(de, { [v]: "", ref: y, children: w });
    }
  );
  d.displayName = u;
  function m(g) {
    const h = s(e + "CollectionConsumer", g);
    return Z.useCallback(() => {
      const w = h.collectionRef.current;
      if (!w) return [];
      const x = Array.from(w.querySelectorAll(`[${v}]`));
      return Array.from(h.itemMap.values()).sort(
        (E, R) => x.indexOf(E.ref.current) - x.indexOf(R.ref.current)
      );
    }, [h.collectionRef, h.itemMap]);
  }
  return [
    { Provider: i, Slot: l, ItemSlot: d },
    m,
    r
  ];
}
var Or = a.createContext(void 0);
function ht(e) {
  const t = a.useContext(Or);
  return e || t || "ltr";
}
function Dr(e, t = globalThis == null ? void 0 : globalThis.document) {
  const n = $(e);
  a.useEffect(() => {
    const r = (o) => {
      o.key === "Escape" && n(o);
    };
    return t.addEventListener("keydown", r, { capture: !0 }), () => t.removeEventListener("keydown", r, { capture: !0 });
  }, [n, t]);
}
var jr = "DismissableLayer", Fe = "dismissableLayer.update", Fr = "dismissableLayer.pointerDownOutside", Lr = "dismissableLayer.focusOutside", nt, gt = a.createContext({
  layers: /* @__PURE__ */ new Set(),
  layersWithOutsidePointerEventsDisabled: /* @__PURE__ */ new Set(),
  branches: /* @__PURE__ */ new Set()
}), wt = a.forwardRef(
  (e, t) => {
    const {
      disableOutsidePointerEvents: n = !1,
      onEscapeKeyDown: r,
      onPointerDownOutside: o,
      onFocusOutside: s,
      onInteractOutside: i,
      onDismiss: c,
      ...l
    } = e, u = a.useContext(gt), [v, d] = a.useState(null), m = (v == null ? void 0 : v.ownerDocument) ?? (globalThis == null ? void 0 : globalThis.document), [, g] = a.useState({}), h = D(t, (S) => d(S)), p = Array.from(u.layers), [w] = [...u.layersWithOutsidePointerEventsDisabled].slice(-1), x = p.indexOf(w), C = v ? p.indexOf(v) : -1, y = u.layersWithOutsidePointerEventsDisabled.size > 0, E = C >= x, R = Ur((S) => {
      const P = S.target, T = [...u.branches].some((j) => j.contains(P));
      !E || T || (o == null || o(S), i == null || i(S), S.defaultPrevented || c == null || c());
    }, m), _ = Br((S) => {
      const P = S.target;
      [...u.branches].some((j) => j.contains(P)) || (s == null || s(S), i == null || i(S), S.defaultPrevented || c == null || c());
    }, m);
    return Dr((S) => {
      C === u.layers.size - 1 && (r == null || r(S), !S.defaultPrevented && c && (S.preventDefault(), c()));
    }, m), a.useEffect(() => {
      if (v)
        return n && (u.layersWithOutsidePointerEventsDisabled.size === 0 && (nt = m.body.style.pointerEvents, m.body.style.pointerEvents = "none"), u.layersWithOutsidePointerEventsDisabled.add(v)), u.layers.add(v), rt(), () => {
          n && u.layersWithOutsidePointerEventsDisabled.size === 1 && (m.body.style.pointerEvents = nt);
        };
    }, [v, m, n, u]), a.useEffect(() => () => {
      v && (u.layers.delete(v), u.layersWithOutsidePointerEventsDisabled.delete(v), rt());
    }, [v, u]), a.useEffect(() => {
      const S = () => g({});
      return document.addEventListener(Fe, S), () => document.removeEventListener(Fe, S);
    }, []), /* @__PURE__ */ f.jsx(
      A.div,
      {
        ...l,
        ref: h,
        style: {
          pointerEvents: y ? E ? "auto" : "none" : void 0,
          ...e.style
        },
        onFocusCapture: M(e.onFocusCapture, _.onFocusCapture),
        onBlurCapture: M(e.onBlurCapture, _.onBlurCapture),
        onPointerDownCapture: M(
          e.onPointerDownCapture,
          R.onPointerDownCapture
        )
      }
    );
  }
);
wt.displayName = jr;
var kr = "DismissableLayerBranch", $r = a.forwardRef((e, t) => {
  const n = a.useContext(gt), r = a.useRef(null), o = D(t, r);
  return a.useEffect(() => {
    const s = r.current;
    if (s)
      return n.branches.add(s), () => {
        n.branches.delete(s);
      };
  }, [n.branches]), /* @__PURE__ */ f.jsx(A.div, { ...e, ref: o });
});
$r.displayName = kr;
function Ur(e, t = globalThis == null ? void 0 : globalThis.document) {
  const n = $(e), r = a.useRef(!1), o = a.useRef(() => {
  });
  return a.useEffect(() => {
    const s = (c) => {
      if (c.target && !r.current) {
        let l = function() {
          xt(
            Fr,
            n,
            u,
            { discrete: !0 }
          );
        };
        const u = { originalEvent: c };
        c.pointerType === "touch" ? (t.removeEventListener("click", o.current), o.current = l, t.addEventListener("click", o.current, { once: !0 })) : l();
      } else
        t.removeEventListener("click", o.current);
      r.current = !1;
    }, i = window.setTimeout(() => {
      t.addEventListener("pointerdown", s);
    }, 0);
    return () => {
      window.clearTimeout(i), t.removeEventListener("pointerdown", s), t.removeEventListener("click", o.current);
    };
  }, [t, n]), {
    // ensures we check React component tree (not just DOM tree)
    onPointerDownCapture: () => r.current = !0
  };
}
function Br(e, t = globalThis == null ? void 0 : globalThis.document) {
  const n = $(e), r = a.useRef(!1);
  return a.useEffect(() => {
    const o = (s) => {
      s.target && !r.current && xt(Lr, n, { originalEvent: s }, {
        discrete: !1
      });
    };
    return t.addEventListener("focusin", o), () => t.removeEventListener("focusin", o);
  }, [t, n]), {
    onFocusCapture: () => r.current = !0,
    onBlurCapture: () => r.current = !1
  };
}
function rt() {
  const e = new CustomEvent(Fe);
  document.dispatchEvent(e);
}
function xt(e, t, n, { discrete: r }) {
  const o = n.originalEvent.target, s = new CustomEvent(e, { bubbles: !1, cancelable: !0, detail: n });
  t && o.addEventListener(e, t, { once: !0 }), r ? mt(o, s) : o.dispatchEvent(s);
}
var Ie = 0;
function Gr() {
  a.useEffect(() => {
    const e = document.querySelectorAll("[data-radix-focus-guard]");
    return document.body.insertAdjacentElement("afterbegin", e[0] ?? ot()), document.body.insertAdjacentElement("beforeend", e[1] ?? ot()), Ie++, () => {
      Ie === 1 && document.querySelectorAll("[data-radix-focus-guard]").forEach((t) => t.remove()), Ie--;
    };
  }, []);
}
function ot() {
  const e = document.createElement("span");
  return e.setAttribute("data-radix-focus-guard", ""), e.tabIndex = 0, e.style.outline = "none", e.style.opacity = "0", e.style.position = "fixed", e.style.pointerEvents = "none", e;
}
var Ae = "focusScope.autoFocusOnMount", Te = "focusScope.autoFocusOnUnmount", at = { bubbles: !1, cancelable: !0 }, Kr = "FocusScope", Ct = a.forwardRef((e, t) => {
  const {
    loop: n = !1,
    trapped: r = !1,
    onMountAutoFocus: o,
    onUnmountAutoFocus: s,
    ...i
  } = e, [c, l] = a.useState(null), u = $(o), v = $(s), d = a.useRef(null), m = D(t, (p) => l(p)), g = a.useRef({
    paused: !1,
    pause() {
      this.paused = !0;
    },
    resume() {
      this.paused = !1;
    }
  }).current;
  a.useEffect(() => {
    if (r) {
      let p = function(y) {
        if (g.paused || !c) return;
        const E = y.target;
        c.contains(E) ? d.current = E : Y(d.current, { select: !0 });
      }, w = function(y) {
        if (g.paused || !c) return;
        const E = y.relatedTarget;
        E !== null && (c.contains(E) || Y(d.current, { select: !0 }));
      }, x = function(y) {
        if (document.activeElement === document.body)
          for (const R of y)
            R.removedNodes.length > 0 && Y(c);
      };
      document.addEventListener("focusin", p), document.addEventListener("focusout", w);
      const C = new MutationObserver(x);
      return c && C.observe(c, { childList: !0, subtree: !0 }), () => {
        document.removeEventListener("focusin", p), document.removeEventListener("focusout", w), C.disconnect();
      };
    }
  }, [r, c, g.paused]), a.useEffect(() => {
    if (c) {
      ct.add(g);
      const p = document.activeElement;
      if (!c.contains(p)) {
        const x = new CustomEvent(Ae, at);
        c.addEventListener(Ae, u), c.dispatchEvent(x), x.defaultPrevented || (Wr(zr(bt(c)), { select: !0 }), document.activeElement === p && Y(c));
      }
      return () => {
        c.removeEventListener(Ae, u), setTimeout(() => {
          const x = new CustomEvent(Te, at);
          c.addEventListener(Te, v), c.dispatchEvent(x), x.defaultPrevented || Y(p ?? document.body, { select: !0 }), c.removeEventListener(Te, v), ct.remove(g);
        }, 0);
      };
    }
  }, [c, u, v, g]);
  const h = a.useCallback(
    (p) => {
      if (!n && !r || g.paused) return;
      const w = p.key === "Tab" && !p.altKey && !p.ctrlKey && !p.metaKey, x = document.activeElement;
      if (w && x) {
        const C = p.currentTarget, [y, E] = Hr(C);
        y && E ? !p.shiftKey && x === E ? (p.preventDefault(), n && Y(y, { select: !0 })) : p.shiftKey && x === y && (p.preventDefault(), n && Y(E, { select: !0 })) : x === C && p.preventDefault();
      }
    },
    [n, r, g.paused]
  );
  return /* @__PURE__ */ f.jsx(A.div, { tabIndex: -1, ...i, ref: m, onKeyDown: h });
});
Ct.displayName = Kr;
function Wr(e, { select: t = !1 } = {}) {
  const n = document.activeElement;
  for (const r of e)
    if (Y(r, { select: t }), document.activeElement !== n) return;
}
function Hr(e) {
  const t = bt(e), n = st(t, e), r = st(t.reverse(), e);
  return [n, r];
}
function bt(e) {
  const t = [], n = document.createTreeWalker(e, NodeFilter.SHOW_ELEMENT, {
    acceptNode: (r) => {
      const o = r.tagName === "INPUT" && r.type === "hidden";
      return r.disabled || r.hidden || o ? NodeFilter.FILTER_SKIP : r.tabIndex >= 0 ? NodeFilter.FILTER_ACCEPT : NodeFilter.FILTER_SKIP;
    }
  });
  for (; n.nextNode(); ) t.push(n.currentNode);
  return t;
}
function st(e, t) {
  for (const n of e)
    if (!Yr(n, { upTo: t })) return n;
}
function Yr(e, { upTo: t }) {
  if (getComputedStyle(e).visibility === "hidden") return !0;
  for (; e; ) {
    if (t !== void 0 && e === t) return !1;
    if (getComputedStyle(e).display === "none") return !0;
    e = e.parentElement;
  }
  return !1;
}
function Vr(e) {
  return e instanceof HTMLInputElement && "select" in e;
}
function Y(e, { select: t = !1 } = {}) {
  if (e && e.focus) {
    const n = document.activeElement;
    e.focus({ preventScroll: !0 }), e !== n && Vr(e) && t && e.select();
  }
}
var ct = Xr();
function Xr() {
  let e = [];
  return {
    add(t) {
      const n = e[0];
      t !== n && (n == null || n.pause()), e = it(e, t), e.unshift(t);
    },
    remove(t) {
      var n;
      e = it(e, t), (n = e[0]) == null || n.resume();
    }
  };
}
function it(e, t) {
  const n = [...e], r = n.indexOf(t);
  return r !== -1 && n.splice(r, 1), n;
}
function zr(e) {
  return e.filter((t) => t.tagName !== "A");
}
var q = globalThis != null && globalThis.document ? a.useLayoutEffect : () => {
}, Zr = a.useId || (() => {
}), qr = 0;
function fe(e) {
  const [t, n] = a.useState(Zr());
  return q(() => {
    e || n((r) => r ?? String(qr++));
  }, [e]), e || (t ? `radix-${t}` : "");
}
var Qr = "Arrow", yt = a.forwardRef((e, t) => {
  const { children: n, width: r = 10, height: o = 5, ...s } = e;
  return /* @__PURE__ */ f.jsx(
    A.svg,
    {
      ...s,
      ref: t,
      width: r,
      height: o,
      viewBox: "0 0 30 10",
      preserveAspectRatio: "none",
      children: e.asChild ? n : /* @__PURE__ */ f.jsx("polygon", { points: "0,0 30,0 15,10" })
    }
  );
});
yt.displayName = Qr;
var Jr = yt;
function eo(e, t = []) {
  let n = [];
  function r(s, i) {
    const c = a.createContext(i), l = n.length;
    n = [...n, i];
    function u(d) {
      const { scope: m, children: g, ...h } = d, p = (m == null ? void 0 : m[e][l]) || c, w = a.useMemo(() => h, Object.values(h));
      return /* @__PURE__ */ f.jsx(p.Provider, { value: w, children: g });
    }
    function v(d, m) {
      const g = (m == null ? void 0 : m[e][l]) || c, h = a.useContext(g);
      if (h) return h;
      if (i !== void 0) return i;
      throw new Error(`\`${d}\` must be used within \`${s}\``);
    }
    return u.displayName = s + "Provider", [u, v];
  }
  const o = () => {
    const s = n.map((i) => a.createContext(i));
    return function(c) {
      const l = (c == null ? void 0 : c[e]) || s;
      return a.useMemo(
        () => ({ [`__scope${e}`]: { ...c, [e]: l } }),
        [c, l]
      );
    };
  };
  return o.scopeName = e, [r, to(o, ...t)];
}
function to(...e) {
  const t = e[0];
  if (e.length === 1) return t;
  const n = () => {
    const r = e.map((o) => ({
      useScope: o(),
      scopeName: o.scopeName
    }));
    return function(s) {
      const i = r.reduce((c, { useScope: l, scopeName: u }) => {
        const d = l(s)[`__scope${u}`];
        return { ...c, ...d };
      }, {});
      return a.useMemo(() => ({ [`__scope${t.scopeName}`]: i }), [i]);
    };
  };
  return n.scopeName = t.scopeName, n;
}
function no(e) {
  const [t, n] = a.useState(void 0);
  return q(() => {
    if (e) {
      n({ width: e.offsetWidth, height: e.offsetHeight });
      const r = new ResizeObserver((o) => {
        if (!Array.isArray(o) || !o.length)
          return;
        const s = o[0];
        let i, c;
        if ("borderBoxSize" in s) {
          const l = s.borderBoxSize, u = Array.isArray(l) ? l[0] : l;
          i = u.inlineSize, c = u.blockSize;
        } else
          i = e.offsetWidth, c = e.offsetHeight;
        n({ width: i, height: c });
      });
      return r.observe(e, { box: "border-box" }), () => r.unobserve(e);
    } else
      n(void 0);
  }, [e]), t;
}
var Ge = "Popper", [Et, St] = eo(Ge), [ro, Mt] = Et(Ge), Rt = (e) => {
  const { __scopePopper: t, children: n } = e, [r, o] = a.useState(null);
  return /* @__PURE__ */ f.jsx(ro, { scope: t, anchor: r, onAnchorChange: o, children: n });
};
Rt.displayName = Ge;
var Pt = "PopperAnchor", _t = a.forwardRef(
  (e, t) => {
    const { __scopePopper: n, virtualRef: r, ...o } = e, s = Mt(Pt, n), i = a.useRef(null), c = D(t, i);
    return a.useEffect(() => {
      s.onAnchorChange((r == null ? void 0 : r.current) || i.current);
    }), r ? null : /* @__PURE__ */ f.jsx(A.div, { ...o, ref: c });
  }
);
_t.displayName = Pt;
var Ke = "PopperContent", [oo, ao] = Et(Ke), Nt = a.forwardRef(
  (e, t) => {
    var W, ce, L, ie, Je, et;
    const {
      __scopePopper: n,
      side: r = "bottom",
      sideOffset: o = 0,
      align: s = "center",
      alignOffset: i = 0,
      arrowPadding: c = 0,
      avoidCollisions: l = !0,
      collisionBoundary: u = [],
      collisionPadding: v = 0,
      sticky: d = "partial",
      hideWhenDetached: m = !1,
      updatePositionStrategy: g = "optimized",
      onPlaced: h,
      ...p
    } = e, w = Mt(Ke, n), [x, C] = a.useState(null), y = D(t, (ue) => C(ue)), [E, R] = a.useState(null), _ = no(E), S = (_ == null ? void 0 : _.width) ?? 0, P = (_ == null ? void 0 : _.height) ?? 0, T = r + (s !== "center" ? "-" + s : ""), j = typeof v == "number" ? v : { top: 0, right: 0, bottom: 0, left: 0, ...v }, H = Array.isArray(u) ? u : [u], X = H.length > 0, K = {
      padding: j,
      boundary: H.filter(co),
      // with `strategy: 'fixed'`, this is the only way to get it to respect boundaries
      altBoundary: X
    }, { refs: J, floatingStyles: ee, placement: U, isPositioned: te, middlewareData: F } = or({
      // default to `fixed` strategy so users don't have to pick and we also avoid focus scroll issues
      strategy: "fixed",
      placement: T,
      whileElementsMounted: (...ue) => ar(...ue, {
        animationFrame: g === "always"
      }),
      elements: {
        reference: w.anchor
      },
      middleware: [
        sr({ mainAxis: o + P, alignmentAxis: i }),
        l && cr({
          mainAxis: !0,
          crossAxis: !1,
          limiter: d === "partial" ? fr() : void 0,
          ...K
        }),
        l && ir({ ...K }),
        ur({
          ...K,
          apply: ({ elements: ue, rects: tt, availableWidth: Qn, availableHeight: Jn }) => {
            const { width: er, height: tr } = tt.reference, Ce = ue.floating.style;
            Ce.setProperty("--radix-popper-available-width", `${Qn}px`), Ce.setProperty("--radix-popper-available-height", `${Jn}px`), Ce.setProperty("--radix-popper-anchor-width", `${er}px`), Ce.setProperty("--radix-popper-anchor-height", `${tr}px`);
          }
        }),
        E && lr({ element: E, padding: c }),
        io({ arrowWidth: S, arrowHeight: P }),
        m && dr({ strategy: "referenceHidden", ...K })
      ]
    }), [b, O] = Tt(U), I = $(h);
    q(() => {
      te && (I == null || I());
    }, [te, I]);
    const B = (W = F.arrow) == null ? void 0 : W.x, ae = (ce = F.arrow) == null ? void 0 : ce.y, se = ((L = F.arrow) == null ? void 0 : L.centerOffset) !== 0, [xe, z] = a.useState();
    return q(() => {
      x && z(window.getComputedStyle(x).zIndex);
    }, [x]), /* @__PURE__ */ f.jsx(
      "div",
      {
        ref: J.setFloating,
        "data-radix-popper-content-wrapper": "",
        style: {
          ...ee,
          transform: te ? ee.transform : "translate(0, -200%)",
          // keep off the page when measuring
          minWidth: "max-content",
          zIndex: xe,
          "--radix-popper-transform-origin": [
            (ie = F.transformOrigin) == null ? void 0 : ie.x,
            (Je = F.transformOrigin) == null ? void 0 : Je.y
          ].join(" "),
          // hide the content if using the hide middleware and should be hidden
          // set visibility to hidden and disable pointer events so the UI behaves
          // as if the PopperContent isn't there at all
          ...((et = F.hide) == null ? void 0 : et.referenceHidden) && {
            visibility: "hidden",
            pointerEvents: "none"
          }
        },
        dir: e.dir,
        children: /* @__PURE__ */ f.jsx(
          oo,
          {
            scope: n,
            placedSide: b,
            onArrowChange: R,
            arrowX: B,
            arrowY: ae,
            shouldHideArrow: se,
            children: /* @__PURE__ */ f.jsx(
              A.div,
              {
                "data-side": b,
                "data-align": O,
                ...p,
                ref: y,
                style: {
                  ...p.style,
                  // if the PopperContent hasn't been placed yet (not all measurements done)
                  // we prevent animations so that users's animation don't kick in too early referring wrong sides
                  animation: te ? void 0 : "none"
                }
              }
            )
          }
        )
      }
    );
  }
);
Nt.displayName = Ke;
var It = "PopperArrow", so = {
  top: "bottom",
  right: "left",
  bottom: "top",
  left: "right"
}, At = a.forwardRef(function(t, n) {
  const { __scopePopper: r, ...o } = t, s = ao(It, r), i = so[s.placedSide];
  return (
    // we have to use an extra wrapper because `ResizeObserver` (used by `useSize`)
    // doesn't report size as we'd expect on SVG elements.
    // it reports their bounding box which is effectively the largest path inside the SVG.
    /* @__PURE__ */ f.jsx(
      "span",
      {
        ref: s.onArrowChange,
        style: {
          position: "absolute",
          left: s.arrowX,
          top: s.arrowY,
          [i]: 0,
          transformOrigin: {
            top: "",
            right: "0 0",
            bottom: "center 0",
            left: "100% 0"
          }[s.placedSide],
          transform: {
            top: "translateY(100%)",
            right: "translateY(50%) rotate(90deg) translateX(-50%)",
            bottom: "rotate(180deg)",
            left: "translateY(50%) rotate(-90deg) translateX(50%)"
          }[s.placedSide],
          visibility: s.shouldHideArrow ? "hidden" : void 0
        },
        children: /* @__PURE__ */ f.jsx(
          Jr,
          {
            ...o,
            ref: n,
            style: {
              ...o.style,
              // ensures the element can be measured correctly (mostly for if SVG)
              display: "block"
            }
          }
        )
      }
    )
  );
});
At.displayName = It;
function co(e) {
  return e !== null;
}
var io = (e) => ({
  name: "transformOrigin",
  options: e,
  fn(t) {
    var w, x, C;
    const { placement: n, rects: r, middlewareData: o } = t, i = ((w = o.arrow) == null ? void 0 : w.centerOffset) !== 0, c = i ? 0 : e.arrowWidth, l = i ? 0 : e.arrowHeight, [u, v] = Tt(n), d = { start: "0%", center: "50%", end: "100%" }[v], m = (((x = o.arrow) == null ? void 0 : x.x) ?? 0) + c / 2, g = (((C = o.arrow) == null ? void 0 : C.y) ?? 0) + l / 2;
    let h = "", p = "";
    return u === "bottom" ? (h = i ? d : `${m}px`, p = `${-l}px`) : u === "top" ? (h = i ? d : `${m}px`, p = `${r.floating.height + l}px`) : u === "right" ? (h = `${-l}px`, p = i ? d : `${g}px`) : u === "left" && (h = `${r.floating.width + l}px`, p = i ? d : `${g}px`), { data: { x: h, y: p } };
  }
});
function Tt(e) {
  const [t, n = "center"] = e.split("-");
  return [t, n];
}
var Ot = Rt, uo = _t, lo = Nt, fo = At, po = "Portal", Dt = a.forwardRef((e, t) => {
  var c;
  const { container: n, ...r } = e, [o, s] = a.useState(!1);
  q(() => s(!0), []);
  const i = n || o && ((c = globalThis == null ? void 0 : globalThis.document) == null ? void 0 : c.body);
  return i ? rr.createPortal(/* @__PURE__ */ f.jsx(A.div, { ...r, ref: t }), i) : null;
});
Dt.displayName = po;
function mo(e, t) {
  return a.useReducer((n, r) => t[n][r] ?? n, e);
}
var ve = (e) => {
  const { present: t, children: n } = e, r = vo(t), o = typeof n == "function" ? n({ present: r.isPresent }) : a.Children.only(n), s = D(r.ref, ho(o));
  return typeof n == "function" || r.isPresent ? a.cloneElement(o, { ref: s }) : null;
};
ve.displayName = "Presence";
function vo(e) {
  const [t, n] = a.useState(), r = a.useRef({}), o = a.useRef(e), s = a.useRef("none"), i = e ? "mounted" : "unmounted", [c, l] = mo(i, {
    mounted: {
      UNMOUNT: "unmounted",
      ANIMATION_OUT: "unmountSuspended"
    },
    unmountSuspended: {
      MOUNT: "mounted",
      ANIMATION_END: "unmounted"
    },
    unmounted: {
      MOUNT: "mounted"
    }
  });
  return a.useEffect(() => {
    const u = be(r.current);
    s.current = c === "mounted" ? u : "none";
  }, [c]), q(() => {
    const u = r.current, v = o.current;
    if (v !== e) {
      const m = s.current, g = be(u);
      e ? l("MOUNT") : g === "none" || (u == null ? void 0 : u.display) === "none" ? l("UNMOUNT") : l(v && m !== g ? "ANIMATION_OUT" : "UNMOUNT"), o.current = e;
    }
  }, [e, l]), q(() => {
    if (t) {
      let u;
      const v = t.ownerDocument.defaultView ?? window, d = (g) => {
        const p = be(r.current).includes(g.animationName);
        if (g.target === t && p && (l("ANIMATION_END"), !o.current)) {
          const w = t.style.animationFillMode;
          t.style.animationFillMode = "forwards", u = v.setTimeout(() => {
            t.style.animationFillMode === "forwards" && (t.style.animationFillMode = w);
          });
        }
      }, m = (g) => {
        g.target === t && (s.current = be(r.current));
      };
      return t.addEventListener("animationstart", m), t.addEventListener("animationcancel", d), t.addEventListener("animationend", d), () => {
        v.clearTimeout(u), t.removeEventListener("animationstart", m), t.removeEventListener("animationcancel", d), t.removeEventListener("animationend", d);
      };
    } else
      l("ANIMATION_END");
  }, [t, l]), {
    isPresent: ["mounted", "unmountSuspended"].includes(c),
    ref: a.useCallback((u) => {
      u && (r.current = getComputedStyle(u)), n(u);
    }, [])
  };
}
function be(e) {
  return (e == null ? void 0 : e.animationName) || "none";
}
function ho(e) {
  var r, o;
  let t = (r = Object.getOwnPropertyDescriptor(e.props, "ref")) == null ? void 0 : r.get, n = t && "isReactWarning" in t && t.isReactWarning;
  return n ? e.ref : (t = (o = Object.getOwnPropertyDescriptor(e, "ref")) == null ? void 0 : o.get, n = t && "isReactWarning" in t && t.isReactWarning, n ? e.props.ref : e.props.ref || e.ref);
}
function go(e, t = []) {
  let n = [];
  function r(s, i) {
    const c = a.createContext(i), l = n.length;
    n = [...n, i];
    function u(d) {
      const { scope: m, children: g, ...h } = d, p = (m == null ? void 0 : m[e][l]) || c, w = a.useMemo(() => h, Object.values(h));
      return /* @__PURE__ */ f.jsx(p.Provider, { value: w, children: g });
    }
    function v(d, m) {
      const g = (m == null ? void 0 : m[e][l]) || c, h = a.useContext(g);
      if (h) return h;
      if (i !== void 0) return i;
      throw new Error(`\`${d}\` must be used within \`${s}\``);
    }
    return u.displayName = s + "Provider", [u, v];
  }
  const o = () => {
    const s = n.map((i) => a.createContext(i));
    return function(c) {
      const l = (c == null ? void 0 : c[e]) || s;
      return a.useMemo(
        () => ({ [`__scope${e}`]: { ...c, [e]: l } }),
        [c, l]
      );
    };
  };
  return o.scopeName = e, [r, wo(o, ...t)];
}
function wo(...e) {
  const t = e[0];
  if (e.length === 1) return t;
  const n = () => {
    const r = e.map((o) => ({
      useScope: o(),
      scopeName: o.scopeName
    }));
    return function(s) {
      const i = r.reduce((c, { useScope: l, scopeName: u }) => {
        const d = l(s)[`__scope${u}`];
        return { ...c, ...d };
      }, {});
      return a.useMemo(() => ({ [`__scope${t.scopeName}`]: i }), [i]);
    };
  };
  return n.scopeName = t.scopeName, n;
}
var Oe = "rovingFocusGroup.onEntryFocus", xo = { bubbles: !1, cancelable: !0 }, Pe = "RovingFocusGroup", [Le, jt, Co] = vt(Pe), [bo, Ft] = go(
  Pe,
  [Co]
), [yo, Eo] = bo(Pe), Lt = a.forwardRef(
  (e, t) => /* @__PURE__ */ f.jsx(Le.Provider, { scope: e.__scopeRovingFocusGroup, children: /* @__PURE__ */ f.jsx(Le.Slot, { scope: e.__scopeRovingFocusGroup, children: /* @__PURE__ */ f.jsx(So, { ...e, ref: t }) }) })
);
Lt.displayName = Pe;
var So = a.forwardRef((e, t) => {
  const {
    __scopeRovingFocusGroup: n,
    orientation: r,
    loop: o = !1,
    dir: s,
    currentTabStopId: i,
    defaultCurrentTabStopId: c,
    onCurrentTabStopIdChange: l,
    onEntryFocus: u,
    preventScrollOnEntryFocus: v = !1,
    ...d
  } = e, m = a.useRef(null), g = D(t, m), h = ht(s), [p = null, w] = Be({
    prop: i,
    defaultProp: c,
    onChange: l
  }), [x, C] = a.useState(!1), y = $(u), E = jt(n), R = a.useRef(!1), [_, S] = a.useState(0);
  return a.useEffect(() => {
    const P = m.current;
    if (P)
      return P.addEventListener(Oe, y), () => P.removeEventListener(Oe, y);
  }, [y]), /* @__PURE__ */ f.jsx(
    yo,
    {
      scope: n,
      orientation: r,
      dir: h,
      loop: o,
      currentTabStopId: p,
      onItemFocus: a.useCallback(
        (P) => w(P),
        [w]
      ),
      onItemShiftTab: a.useCallback(() => C(!0), []),
      onFocusableItemAdd: a.useCallback(
        () => S((P) => P + 1),
        []
      ),
      onFocusableItemRemove: a.useCallback(
        () => S((P) => P - 1),
        []
      ),
      children: /* @__PURE__ */ f.jsx(
        A.div,
        {
          tabIndex: x || _ === 0 ? -1 : 0,
          "data-orientation": r,
          ...d,
          ref: g,
          style: { outline: "none", ...e.style },
          onMouseDown: M(e.onMouseDown, () => {
            R.current = !0;
          }),
          onFocus: M(e.onFocus, (P) => {
            const T = !R.current;
            if (P.target === P.currentTarget && T && !x) {
              const j = new CustomEvent(Oe, xo);
              if (P.currentTarget.dispatchEvent(j), !j.defaultPrevented) {
                const H = E().filter((U) => U.focusable), X = H.find((U) => U.active), K = H.find((U) => U.id === p), ee = [X, K, ...H].filter(
                  Boolean
                ).map((U) => U.ref.current);
                Ut(ee, v);
              }
            }
            R.current = !1;
          }),
          onBlur: M(e.onBlur, () => C(!1))
        }
      )
    }
  );
}), kt = "RovingFocusGroupItem", $t = a.forwardRef(
  (e, t) => {
    const {
      __scopeRovingFocusGroup: n,
      focusable: r = !0,
      active: o = !1,
      tabStopId: s,
      ...i
    } = e, c = fe(), l = s || c, u = Eo(kt, n), v = u.currentTabStopId === l, d = jt(n), { onFocusableItemAdd: m, onFocusableItemRemove: g } = u;
    return a.useEffect(() => {
      if (r)
        return m(), () => g();
    }, [r, m, g]), /* @__PURE__ */ f.jsx(
      Le.ItemSlot,
      {
        scope: n,
        id: l,
        focusable: r,
        active: o,
        children: /* @__PURE__ */ f.jsx(
          A.span,
          {
            tabIndex: v ? 0 : -1,
            "data-orientation": u.orientation,
            ...i,
            ref: t,
            onMouseDown: M(e.onMouseDown, (h) => {
              r ? u.onItemFocus(l) : h.preventDefault();
            }),
            onFocus: M(e.onFocus, () => u.onItemFocus(l)),
            onKeyDown: M(e.onKeyDown, (h) => {
              if (h.key === "Tab" && h.shiftKey) {
                u.onItemShiftTab();
                return;
              }
              if (h.target !== h.currentTarget) return;
              const p = Po(h, u.orientation, u.dir);
              if (p !== void 0) {
                if (h.metaKey || h.ctrlKey || h.altKey || h.shiftKey) return;
                h.preventDefault();
                let x = d().filter((C) => C.focusable).map((C) => C.ref.current);
                if (p === "last") x.reverse();
                else if (p === "prev" || p === "next") {
                  p === "prev" && x.reverse();
                  const C = x.indexOf(h.currentTarget);
                  x = u.loop ? _o(x, C + 1) : x.slice(C + 1);
                }
                setTimeout(() => Ut(x));
              }
            })
          }
        )
      }
    );
  }
);
$t.displayName = kt;
var Mo = {
  ArrowLeft: "prev",
  ArrowUp: "prev",
  ArrowRight: "next",
  ArrowDown: "next",
  PageUp: "first",
  Home: "first",
  PageDown: "last",
  End: "last"
};
function Ro(e, t) {
  return t !== "rtl" ? e : e === "ArrowLeft" ? "ArrowRight" : e === "ArrowRight" ? "ArrowLeft" : e;
}
function Po(e, t, n) {
  const r = Ro(e.key, n);
  if (!(t === "vertical" && ["ArrowLeft", "ArrowRight"].includes(r)) && !(t === "horizontal" && ["ArrowUp", "ArrowDown"].includes(r)))
    return Mo[r];
}
function Ut(e, t = !1) {
  const n = document.activeElement;
  for (const r of e)
    if (r === n || (r.focus({ preventScroll: t }), document.activeElement !== n)) return;
}
function _o(e, t) {
  return e.map((n, r) => e[(t + r) % e.length]);
}
var No = Lt, Io = $t, Bt = pr(), De = function() {
}, _e = a.forwardRef(function(e, t) {
  var n = a.useRef(null), r = a.useState({
    onScrollCapture: De,
    onWheelCapture: De,
    onTouchMoveCapture: De
  }), o = r[0], s = r[1], i = e.forwardProps, c = e.children, l = e.className, u = e.removeScrollBar, v = e.enabled, d = e.shards, m = e.sideCar, g = e.noIsolation, h = e.inert, p = e.allowPinchZoom, w = e.as, x = w === void 0 ? "div" : w, C = e.gapMode, y = br(e, ["forwardProps", "children", "className", "removeScrollBar", "enabled", "shards", "sideCar", "noIsolation", "inert", "allowPinchZoom", "as", "gapMode"]), E = m, R = mr([n, t]), _ = oe(oe({}, y), o);
  return a.createElement(
    a.Fragment,
    null,
    v && a.createElement(E, { sideCar: Bt, removeScrollBar: u, shards: d, noIsolation: g, inert: h, setCallbacks: s, allowPinchZoom: !!p, lockRef: n, gapMode: C }),
    i ? a.cloneElement(a.Children.only(c), oe(oe({}, _), { ref: R })) : a.createElement(x, oe({}, _, { className: l, ref: R }), c)
  );
});
_e.defaultProps = {
  enabled: !0,
  removeScrollBar: !0,
  inert: !1
};
_e.classNames = {
  fullWidth: vr,
  zeroRight: hr
};
var ke = !1;
if (typeof window < "u")
  try {
    var ye = Object.defineProperty({}, "passive", {
      get: function() {
        return ke = !0, !0;
      }
    });
    window.addEventListener("test", ye, ye), window.removeEventListener("test", ye, ye);
  } catch {
    ke = !1;
  }
var ne = ke ? { passive: !1 } : !1, Ao = function(e) {
  return e.tagName === "TEXTAREA";
}, Gt = function(e, t) {
  if (!(e instanceof Element))
    return !1;
  var n = window.getComputedStyle(e);
  return (
    // not-not-scrollable
    n[t] !== "hidden" && // contains scroll inside self
    !(n.overflowY === n.overflowX && !Ao(e) && n[t] === "visible")
  );
}, To = function(e) {
  return Gt(e, "overflowY");
}, Oo = function(e) {
  return Gt(e, "overflowX");
}, ut = function(e, t) {
  var n = t.ownerDocument, r = t;
  do {
    typeof ShadowRoot < "u" && r instanceof ShadowRoot && (r = r.host);
    var o = Kt(e, r);
    if (o) {
      var s = Wt(e, r), i = s[1], c = s[2];
      if (i > c)
        return !0;
    }
    r = r.parentNode;
  } while (r && r !== n.body);
  return !1;
}, Do = function(e) {
  var t = e.scrollTop, n = e.scrollHeight, r = e.clientHeight;
  return [
    t,
    n,
    r
  ];
}, jo = function(e) {
  var t = e.scrollLeft, n = e.scrollWidth, r = e.clientWidth;
  return [
    t,
    n,
    r
  ];
}, Kt = function(e, t) {
  return e === "v" ? To(t) : Oo(t);
}, Wt = function(e, t) {
  return e === "v" ? Do(t) : jo(t);
}, Fo = function(e, t) {
  return e === "h" && t === "rtl" ? -1 : 1;
}, Lo = function(e, t, n, r, o) {
  var s = Fo(e, window.getComputedStyle(t).direction), i = s * r, c = n.target, l = t.contains(c), u = !1, v = i > 0, d = 0, m = 0;
  do {
    var g = Wt(e, c), h = g[0], p = g[1], w = g[2], x = p - w - s * h;
    (h || x) && Kt(e, c) && (d += x, m += h), c instanceof ShadowRoot ? c = c.host : c = c.parentNode;
  } while (
    // portaled content
    !l && c !== document.body || // self content
    l && (t.contains(c) || t === c)
  );
  return (v && (Math.abs(d) < 1 || !o) || !v && (Math.abs(m) < 1 || !o)) && (u = !0), u;
}, Ee = function(e) {
  return "changedTouches" in e ? [e.changedTouches[0].clientX, e.changedTouches[0].clientY] : [0, 0];
}, lt = function(e) {
  return [e.deltaX, e.deltaY];
}, dt = function(e) {
  return e && "current" in e ? e.current : e;
}, ko = function(e, t) {
  return e[0] === t[0] && e[1] === t[1];
}, $o = function(e) {
  return `
  .block-interactivity-`.concat(e, ` {pointer-events: none;}
  .allow-interactivity-`).concat(e, ` {pointer-events: all;}
`);
}, Uo = 0, re = [];
function Bo(e) {
  var t = a.useRef([]), n = a.useRef([0, 0]), r = a.useRef(), o = a.useState(Uo++)[0], s = a.useState(gr)[0], i = a.useRef(e);
  a.useEffect(function() {
    i.current = e;
  }, [e]), a.useEffect(function() {
    if (e.inert) {
      document.body.classList.add("block-interactivity-".concat(o));
      var p = yr([e.lockRef.current], (e.shards || []).map(dt), !0).filter(Boolean);
      return p.forEach(function(w) {
        return w.classList.add("allow-interactivity-".concat(o));
      }), function() {
        document.body.classList.remove("block-interactivity-".concat(o)), p.forEach(function(w) {
          return w.classList.remove("allow-interactivity-".concat(o));
        });
      };
    }
  }, [e.inert, e.lockRef.current, e.shards]);
  var c = a.useCallback(function(p, w) {
    if ("touches" in p && p.touches.length === 2 || p.type === "wheel" && p.ctrlKey)
      return !i.current.allowPinchZoom;
    var x = Ee(p), C = n.current, y = "deltaX" in p ? p.deltaX : C[0] - x[0], E = "deltaY" in p ? p.deltaY : C[1] - x[1], R, _ = p.target, S = Math.abs(y) > Math.abs(E) ? "h" : "v";
    if ("touches" in p && S === "h" && _.type === "range")
      return !1;
    var P = ut(S, _);
    if (!P)
      return !0;
    if (P ? R = S : (R = S === "v" ? "h" : "v", P = ut(S, _)), !P)
      return !1;
    if (!r.current && "changedTouches" in p && (y || E) && (r.current = R), !R)
      return !0;
    var T = r.current || R;
    return Lo(T, w, p, T === "h" ? y : E, !0);
  }, []), l = a.useCallback(function(p) {
    var w = p;
    if (!(!re.length || re[re.length - 1] !== s)) {
      var x = "deltaY" in w ? lt(w) : Ee(w), C = t.current.filter(function(R) {
        return R.name === w.type && (R.target === w.target || w.target === R.shadowParent) && ko(R.delta, x);
      })[0];
      if (C && C.should) {
        w.cancelable && w.preventDefault();
        return;
      }
      if (!C) {
        var y = (i.current.shards || []).map(dt).filter(Boolean).filter(function(R) {
          return R.contains(w.target);
        }), E = y.length > 0 ? c(w, y[0]) : !i.current.noIsolation;
        E && w.cancelable && w.preventDefault();
      }
    }
  }, []), u = a.useCallback(function(p, w, x, C) {
    var y = { name: p, delta: w, target: x, should: C, shadowParent: Go(x) };
    t.current.push(y), setTimeout(function() {
      t.current = t.current.filter(function(E) {
        return E !== y;
      });
    }, 1);
  }, []), v = a.useCallback(function(p) {
    n.current = Ee(p), r.current = void 0;
  }, []), d = a.useCallback(function(p) {
    u(p.type, lt(p), p.target, c(p, e.lockRef.current));
  }, []), m = a.useCallback(function(p) {
    u(p.type, Ee(p), p.target, c(p, e.lockRef.current));
  }, []);
  a.useEffect(function() {
    return re.push(s), e.setCallbacks({
      onScrollCapture: d,
      onWheelCapture: d,
      onTouchMoveCapture: m
    }), document.addEventListener("wheel", l, ne), document.addEventListener("touchmove", l, ne), document.addEventListener("touchstart", v, ne), function() {
      re = re.filter(function(p) {
        return p !== s;
      }), document.removeEventListener("wheel", l, ne), document.removeEventListener("touchmove", l, ne), document.removeEventListener("touchstart", v, ne);
    };
  }, []);
  var g = e.removeScrollBar, h = e.inert;
  return a.createElement(
    a.Fragment,
    null,
    h ? a.createElement(s, { styles: $o(o) }) : null,
    g ? a.createElement(wr, { gapMode: e.gapMode }) : null
  );
}
function Go(e) {
  for (var t = null; e !== null; )
    e instanceof ShadowRoot && (t = e.host, e = e.host), e = e.parentNode;
  return t;
}
const Ko = xr(Bt, Bo);
var Ht = a.forwardRef(function(e, t) {
  return a.createElement(_e, oe({}, e, { ref: t, sideCar: Ko }));
});
Ht.classNames = _e.classNames;
var $e = ["Enter", " "], Wo = ["ArrowDown", "PageUp", "Home"], Yt = ["ArrowUp", "PageDown", "End"], Ho = [...Wo, ...Yt], Yo = {
  ltr: [...$e, "ArrowRight"],
  rtl: [...$e, "ArrowLeft"]
}, Vo = {
  ltr: ["ArrowLeft"],
  rtl: ["ArrowRight"]
}, he = "Menu", [pe, Xo, zo] = vt(he), [Q, Vt] = pt(he, [
  zo,
  St,
  Ft
]), ge = St(), Xt = Ft(), [zt, V] = Q(he), [Zo, we] = Q(he), Zt = (e) => {
  const { __scopeMenu: t, open: n = !1, children: r, dir: o, onOpenChange: s, modal: i = !0 } = e, c = ge(t), [l, u] = a.useState(null), v = a.useRef(!1), d = $(s), m = ht(o);
  return a.useEffect(() => {
    const g = () => {
      v.current = !0, document.addEventListener("pointerdown", h, { capture: !0, once: !0 }), document.addEventListener("pointermove", h, { capture: !0, once: !0 });
    }, h = () => v.current = !1;
    return document.addEventListener("keydown", g, { capture: !0 }), () => {
      document.removeEventListener("keydown", g, { capture: !0 }), document.removeEventListener("pointerdown", h, { capture: !0 }), document.removeEventListener("pointermove", h, { capture: !0 });
    };
  }, []), /* @__PURE__ */ f.jsx(Ot, { ...c, children: /* @__PURE__ */ f.jsx(
    zt,
    {
      scope: t,
      open: n,
      onOpenChange: d,
      content: l,
      onContentChange: u,
      children: /* @__PURE__ */ f.jsx(
        Zo,
        {
          scope: t,
          onClose: a.useCallback(() => d(!1), [d]),
          isUsingKeyboardRef: v,
          dir: m,
          modal: i,
          children: r
        }
      )
    }
  ) });
};
Zt.displayName = he;
var qo = "MenuAnchor", We = a.forwardRef(
  (e, t) => {
    const { __scopeMenu: n, ...r } = e, o = ge(n);
    return /* @__PURE__ */ f.jsx(uo, { ...o, ...r, ref: t });
  }
);
We.displayName = qo;
var He = "MenuPortal", [Qo, qt] = Q(He, {
  forceMount: void 0
}), Qt = (e) => {
  const { __scopeMenu: t, forceMount: n, children: r, container: o } = e, s = V(He, t);
  return /* @__PURE__ */ f.jsx(Qo, { scope: t, forceMount: n, children: /* @__PURE__ */ f.jsx(ve, { present: n || s.open, children: /* @__PURE__ */ f.jsx(Dt, { asChild: !0, container: o, children: r }) }) });
};
Qt.displayName = He;
var k = "MenuContent", [Jo, Ye] = Q(k), Jt = a.forwardRef(
  (e, t) => {
    const n = qt(k, e.__scopeMenu), { forceMount: r = n.forceMount, ...o } = e, s = V(k, e.__scopeMenu), i = we(k, e.__scopeMenu);
    return /* @__PURE__ */ f.jsx(pe.Provider, { scope: e.__scopeMenu, children: /* @__PURE__ */ f.jsx(ve, { present: r || s.open, children: /* @__PURE__ */ f.jsx(pe.Slot, { scope: e.__scopeMenu, children: i.modal ? /* @__PURE__ */ f.jsx(ea, { ...o, ref: t }) : /* @__PURE__ */ f.jsx(ta, { ...o, ref: t }) }) }) });
  }
), ea = a.forwardRef(
  (e, t) => {
    const n = V(k, e.__scopeMenu), r = a.useRef(null), o = D(t, r);
    return a.useEffect(() => {
      const s = r.current;
      if (s) return Cr(s);
    }, []), /* @__PURE__ */ f.jsx(
      Ve,
      {
        ...e,
        ref: o,
        trapFocus: n.open,
        disableOutsidePointerEvents: n.open,
        disableOutsideScroll: !0,
        onFocusOutside: M(
          e.onFocusOutside,
          (s) => s.preventDefault(),
          { checkForDefaultPrevented: !1 }
        ),
        onDismiss: () => n.onOpenChange(!1)
      }
    );
  }
), ta = a.forwardRef((e, t) => {
  const n = V(k, e.__scopeMenu);
  return /* @__PURE__ */ f.jsx(
    Ve,
    {
      ...e,
      ref: t,
      trapFocus: !1,
      disableOutsidePointerEvents: !1,
      disableOutsideScroll: !1,
      onDismiss: () => n.onOpenChange(!1)
    }
  );
}), Ve = a.forwardRef(
  (e, t) => {
    const {
      __scopeMenu: n,
      loop: r = !1,
      trapFocus: o,
      onOpenAutoFocus: s,
      onCloseAutoFocus: i,
      disableOutsidePointerEvents: c,
      onEntryFocus: l,
      onEscapeKeyDown: u,
      onPointerDownOutside: v,
      onFocusOutside: d,
      onInteractOutside: m,
      onDismiss: g,
      disableOutsideScroll: h,
      ...p
    } = e, w = V(k, n), x = we(k, n), C = ge(n), y = Xt(n), E = Xo(n), [R, _] = a.useState(null), S = a.useRef(null), P = D(t, S, w.onContentChange), T = a.useRef(0), j = a.useRef(""), H = a.useRef(0), X = a.useRef(null), K = a.useRef("right"), J = a.useRef(0), ee = h ? Ht : a.Fragment, U = h ? { as: de, allowPinchZoom: !0 } : void 0, te = (b) => {
      var W, ce;
      const O = j.current + b, I = E().filter((L) => !L.disabled), B = document.activeElement, ae = (W = I.find((L) => L.ref.current === B)) == null ? void 0 : W.textValue, se = I.map((L) => L.textValue), xe = pa(se, O, ae), z = (ce = I.find((L) => L.textValue === xe)) == null ? void 0 : ce.ref.current;
      (function L(ie) {
        j.current = ie, window.clearTimeout(T.current), ie !== "" && (T.current = window.setTimeout(() => L(""), 1e3));
      })(O), z && setTimeout(() => z.focus());
    };
    a.useEffect(() => () => window.clearTimeout(T.current), []), Gr();
    const F = a.useCallback((b) => {
      var I, B;
      return K.current === ((I = X.current) == null ? void 0 : I.side) && va(b, (B = X.current) == null ? void 0 : B.area);
    }, []);
    return /* @__PURE__ */ f.jsx(
      Jo,
      {
        scope: n,
        searchRef: j,
        onItemEnter: a.useCallback(
          (b) => {
            F(b) && b.preventDefault();
          },
          [F]
        ),
        onItemLeave: a.useCallback(
          (b) => {
            var O;
            F(b) || ((O = S.current) == null || O.focus(), _(null));
          },
          [F]
        ),
        onTriggerLeave: a.useCallback(
          (b) => {
            F(b) && b.preventDefault();
          },
          [F]
        ),
        pointerGraceTimerRef: H,
        onPointerGraceIntentChange: a.useCallback((b) => {
          X.current = b;
        }, []),
        children: /* @__PURE__ */ f.jsx(ee, { ...U, children: /* @__PURE__ */ f.jsx(
          Ct,
          {
            asChild: !0,
            trapped: o,
            onMountAutoFocus: M(s, (b) => {
              var O;
              b.preventDefault(), (O = S.current) == null || O.focus({ preventScroll: !0 });
            }),
            onUnmountAutoFocus: i,
            children: /* @__PURE__ */ f.jsx(
              wt,
              {
                asChild: !0,
                disableOutsidePointerEvents: c,
                onEscapeKeyDown: u,
                onPointerDownOutside: v,
                onFocusOutside: d,
                onInteractOutside: m,
                onDismiss: g,
                children: /* @__PURE__ */ f.jsx(
                  No,
                  {
                    asChild: !0,
                    ...y,
                    dir: x.dir,
                    orientation: "vertical",
                    loop: r,
                    currentTabStopId: R,
                    onCurrentTabStopIdChange: _,
                    onEntryFocus: M(l, (b) => {
                      x.isUsingKeyboardRef.current || b.preventDefault();
                    }),
                    preventScrollOnEntryFocus: !0,
                    children: /* @__PURE__ */ f.jsx(
                      lo,
                      {
                        role: "menu",
                        "aria-orientation": "vertical",
                        "data-state": gn(w.open),
                        "data-radix-menu-content": "",
                        dir: x.dir,
                        ...C,
                        ...p,
                        ref: P,
                        style: { outline: "none", ...p.style },
                        onKeyDown: M(p.onKeyDown, (b) => {
                          const I = b.target.closest("[data-radix-menu-content]") === b.currentTarget, B = b.ctrlKey || b.altKey || b.metaKey, ae = b.key.length === 1;
                          I && (b.key === "Tab" && b.preventDefault(), !B && ae && te(b.key));
                          const se = S.current;
                          if (b.target !== se || !Ho.includes(b.key)) return;
                          b.preventDefault();
                          const z = E().filter((W) => !W.disabled).map((W) => W.ref.current);
                          Yt.includes(b.key) && z.reverse(), da(z);
                        }),
                        onBlur: M(e.onBlur, (b) => {
                          b.currentTarget.contains(b.target) || (window.clearTimeout(T.current), j.current = "");
                        }),
                        onPointerMove: M(
                          e.onPointerMove,
                          me((b) => {
                            const O = b.target, I = J.current !== b.clientX;
                            if (b.currentTarget.contains(O) && I) {
                              const B = b.clientX > J.current ? "right" : "left";
                              K.current = B, J.current = b.clientX;
                            }
                          })
                        )
                      }
                    )
                  }
                )
              }
            )
          }
        ) })
      }
    );
  }
);
Jt.displayName = k;
var na = "MenuGroup", Xe = a.forwardRef(
  (e, t) => {
    const { __scopeMenu: n, ...r } = e;
    return /* @__PURE__ */ f.jsx(A.div, { role: "group", ...r, ref: t });
  }
);
Xe.displayName = na;
var ra = "MenuLabel", en = a.forwardRef(
  (e, t) => {
    const { __scopeMenu: n, ...r } = e;
    return /* @__PURE__ */ f.jsx(A.div, { ...r, ref: t });
  }
);
en.displayName = ra;
var Se = "MenuItem", ft = "menu.itemSelect", Ne = a.forwardRef(
  (e, t) => {
    const { disabled: n = !1, onSelect: r, ...o } = e, s = a.useRef(null), i = we(Se, e.__scopeMenu), c = Ye(Se, e.__scopeMenu), l = D(t, s), u = a.useRef(!1), v = () => {
      const d = s.current;
      if (!n && d) {
        const m = new CustomEvent(ft, { bubbles: !0, cancelable: !0 });
        d.addEventListener(ft, (g) => r == null ? void 0 : r(g), { once: !0 }), mt(d, m), m.defaultPrevented ? u.current = !1 : i.onClose();
      }
    };
    return /* @__PURE__ */ f.jsx(
      tn,
      {
        ...o,
        ref: l,
        disabled: n,
        onClick: M(e.onClick, v),
        onPointerDown: (d) => {
          var m;
          (m = e.onPointerDown) == null || m.call(e, d), u.current = !0;
        },
        onPointerUp: M(e.onPointerUp, (d) => {
          var m;
          u.current || (m = d.currentTarget) == null || m.click();
        }),
        onKeyDown: M(e.onKeyDown, (d) => {
          const m = c.searchRef.current !== "";
          n || m && d.key === " " || $e.includes(d.key) && (d.currentTarget.click(), d.preventDefault());
        })
      }
    );
  }
);
Ne.displayName = Se;
var tn = a.forwardRef(
  (e, t) => {
    const { __scopeMenu: n, disabled: r = !1, textValue: o, ...s } = e, i = Ye(Se, n), c = Xt(n), l = a.useRef(null), u = D(t, l), [v, d] = a.useState(!1), [m, g] = a.useState("");
    return a.useEffect(() => {
      const h = l.current;
      h && g((h.textContent ?? "").trim());
    }, [s.children]), /* @__PURE__ */ f.jsx(
      pe.ItemSlot,
      {
        scope: n,
        disabled: r,
        textValue: o ?? m,
        children: /* @__PURE__ */ f.jsx(Io, { asChild: !0, ...c, focusable: !r, children: /* @__PURE__ */ f.jsx(
          A.div,
          {
            role: "menuitem",
            "data-highlighted": v ? "" : void 0,
            "aria-disabled": r || void 0,
            "data-disabled": r ? "" : void 0,
            ...s,
            ref: u,
            onPointerMove: M(
              e.onPointerMove,
              me((h) => {
                r ? i.onItemLeave(h) : (i.onItemEnter(h), h.defaultPrevented || h.currentTarget.focus({ preventScroll: !0 }));
              })
            ),
            onPointerLeave: M(
              e.onPointerLeave,
              me((h) => i.onItemLeave(h))
            ),
            onFocus: M(e.onFocus, () => d(!0)),
            onBlur: M(e.onBlur, () => d(!1))
          }
        ) })
      }
    );
  }
), oa = "MenuCheckboxItem", nn = a.forwardRef(
  (e, t) => {
    const { checked: n = !1, onCheckedChange: r, ...o } = e;
    return /* @__PURE__ */ f.jsx(cn, { scope: e.__scopeMenu, checked: n, children: /* @__PURE__ */ f.jsx(
      Ne,
      {
        role: "menuitemcheckbox",
        "aria-checked": Me(n) ? "mixed" : n,
        ...o,
        ref: t,
        "data-state": qe(n),
        onSelect: M(
          o.onSelect,
          () => r == null ? void 0 : r(Me(n) ? !0 : !n),
          { checkForDefaultPrevented: !1 }
        )
      }
    ) });
  }
);
nn.displayName = oa;
var rn = "MenuRadioGroup", [aa, sa] = Q(
  rn,
  { value: void 0, onValueChange: () => {
  } }
), on = a.forwardRef(
  (e, t) => {
    const { value: n, onValueChange: r, ...o } = e, s = $(r);
    return /* @__PURE__ */ f.jsx(aa, { scope: e.__scopeMenu, value: n, onValueChange: s, children: /* @__PURE__ */ f.jsx(Xe, { ...o, ref: t }) });
  }
);
on.displayName = rn;
var an = "MenuRadioItem", sn = a.forwardRef(
  (e, t) => {
    const { value: n, ...r } = e, o = sa(an, e.__scopeMenu), s = n === o.value;
    return /* @__PURE__ */ f.jsx(cn, { scope: e.__scopeMenu, checked: s, children: /* @__PURE__ */ f.jsx(
      Ne,
      {
        role: "menuitemradio",
        "aria-checked": s,
        ...r,
        ref: t,
        "data-state": qe(s),
        onSelect: M(
          r.onSelect,
          () => {
            var i;
            return (i = o.onValueChange) == null ? void 0 : i.call(o, n);
          },
          { checkForDefaultPrevented: !1 }
        )
      }
    ) });
  }
);
sn.displayName = an;
var ze = "MenuItemIndicator", [cn, ca] = Q(
  ze,
  { checked: !1 }
), un = a.forwardRef(
  (e, t) => {
    const { __scopeMenu: n, forceMount: r, ...o } = e, s = ca(ze, n);
    return /* @__PURE__ */ f.jsx(
      ve,
      {
        present: r || Me(s.checked) || s.checked === !0,
        children: /* @__PURE__ */ f.jsx(
          A.span,
          {
            ...o,
            ref: t,
            "data-state": qe(s.checked)
          }
        )
      }
    );
  }
);
un.displayName = ze;
var ia = "MenuSeparator", ln = a.forwardRef(
  (e, t) => {
    const { __scopeMenu: n, ...r } = e;
    return /* @__PURE__ */ f.jsx(
      A.div,
      {
        role: "separator",
        "aria-orientation": "horizontal",
        ...r,
        ref: t
      }
    );
  }
);
ln.displayName = ia;
var ua = "MenuArrow", dn = a.forwardRef(
  (e, t) => {
    const { __scopeMenu: n, ...r } = e, o = ge(n);
    return /* @__PURE__ */ f.jsx(fo, { ...o, ...r, ref: t });
  }
);
dn.displayName = ua;
var Ze = "MenuSub", [la, fn] = Q(Ze), pn = (e) => {
  const { __scopeMenu: t, children: n, open: r = !1, onOpenChange: o } = e, s = V(Ze, t), i = ge(t), [c, l] = a.useState(null), [u, v] = a.useState(null), d = $(o);
  return a.useEffect(() => (s.open === !1 && d(!1), () => d(!1)), [s.open, d]), /* @__PURE__ */ f.jsx(Ot, { ...i, children: /* @__PURE__ */ f.jsx(
    zt,
    {
      scope: t,
      open: r,
      onOpenChange: d,
      content: u,
      onContentChange: v,
      children: /* @__PURE__ */ f.jsx(
        la,
        {
          scope: t,
          contentId: fe(),
          triggerId: fe(),
          trigger: c,
          onTriggerChange: l,
          children: n
        }
      )
    }
  ) });
};
pn.displayName = Ze;
var le = "MenuSubTrigger", mn = a.forwardRef(
  (e, t) => {
    const n = V(le, e.__scopeMenu), r = we(le, e.__scopeMenu), o = fn(le, e.__scopeMenu), s = Ye(le, e.__scopeMenu), i = a.useRef(null), { pointerGraceTimerRef: c, onPointerGraceIntentChange: l } = s, u = { __scopeMenu: e.__scopeMenu }, v = a.useCallback(() => {
      i.current && window.clearTimeout(i.current), i.current = null;
    }, []);
    return a.useEffect(() => v, [v]), a.useEffect(() => {
      const d = c.current;
      return () => {
        window.clearTimeout(d), l(null);
      };
    }, [c, l]), /* @__PURE__ */ f.jsx(We, { asChild: !0, ...u, children: /* @__PURE__ */ f.jsx(
      tn,
      {
        id: o.triggerId,
        "aria-haspopup": "menu",
        "aria-expanded": n.open,
        "aria-controls": o.contentId,
        "data-state": gn(n.open),
        ...e,
        ref: Re(t, o.onTriggerChange),
        onClick: (d) => {
          var m;
          (m = e.onClick) == null || m.call(e, d), !(e.disabled || d.defaultPrevented) && (d.currentTarget.focus(), n.open || n.onOpenChange(!0));
        },
        onPointerMove: M(
          e.onPointerMove,
          me((d) => {
            s.onItemEnter(d), !d.defaultPrevented && !e.disabled && !n.open && !i.current && (s.onPointerGraceIntentChange(null), i.current = window.setTimeout(() => {
              n.onOpenChange(!0), v();
            }, 100));
          })
        ),
        onPointerLeave: M(
          e.onPointerLeave,
          me((d) => {
            var g, h;
            v();
            const m = (g = n.content) == null ? void 0 : g.getBoundingClientRect();
            if (m) {
              const p = (h = n.content) == null ? void 0 : h.dataset.side, w = p === "right", x = w ? -5 : 5, C = m[w ? "left" : "right"], y = m[w ? "right" : "left"];
              s.onPointerGraceIntentChange({
                area: [
                  // Apply a bleed on clientX to ensure that our exit point is
                  // consistently within polygon bounds
                  { x: d.clientX + x, y: d.clientY },
                  { x: C, y: m.top },
                  { x: y, y: m.top },
                  { x: y, y: m.bottom },
                  { x: C, y: m.bottom }
                ],
                side: p
              }), window.clearTimeout(c.current), c.current = window.setTimeout(
                () => s.onPointerGraceIntentChange(null),
                300
              );
            } else {
              if (s.onTriggerLeave(d), d.defaultPrevented) return;
              s.onPointerGraceIntentChange(null);
            }
          })
        ),
        onKeyDown: M(e.onKeyDown, (d) => {
          var g;
          const m = s.searchRef.current !== "";
          e.disabled || m && d.key === " " || Yo[r.dir].includes(d.key) && (n.onOpenChange(!0), (g = n.content) == null || g.focus(), d.preventDefault());
        })
      }
    ) });
  }
);
mn.displayName = le;
var vn = "MenuSubContent", hn = a.forwardRef(
  (e, t) => {
    const n = qt(k, e.__scopeMenu), { forceMount: r = n.forceMount, ...o } = e, s = V(k, e.__scopeMenu), i = we(k, e.__scopeMenu), c = fn(vn, e.__scopeMenu), l = a.useRef(null), u = D(t, l);
    return /* @__PURE__ */ f.jsx(pe.Provider, { scope: e.__scopeMenu, children: /* @__PURE__ */ f.jsx(ve, { present: r || s.open, children: /* @__PURE__ */ f.jsx(pe.Slot, { scope: e.__scopeMenu, children: /* @__PURE__ */ f.jsx(
      Ve,
      {
        id: c.contentId,
        "aria-labelledby": c.triggerId,
        ...o,
        ref: u,
        align: "start",
        side: i.dir === "rtl" ? "left" : "right",
        disableOutsidePointerEvents: !1,
        disableOutsideScroll: !1,
        trapFocus: !1,
        onOpenAutoFocus: (v) => {
          var d;
          i.isUsingKeyboardRef.current && ((d = l.current) == null || d.focus()), v.preventDefault();
        },
        onCloseAutoFocus: (v) => v.preventDefault(),
        onFocusOutside: M(e.onFocusOutside, (v) => {
          v.target !== c.trigger && s.onOpenChange(!1);
        }),
        onEscapeKeyDown: M(e.onEscapeKeyDown, (v) => {
          i.onClose(), v.preventDefault();
        }),
        onKeyDown: M(e.onKeyDown, (v) => {
          var g;
          const d = v.currentTarget.contains(v.target), m = Vo[i.dir].includes(v.key);
          d && m && (s.onOpenChange(!1), (g = c.trigger) == null || g.focus(), v.preventDefault());
        })
      }
    ) }) }) });
  }
);
hn.displayName = vn;
function gn(e) {
  return e ? "open" : "closed";
}
function Me(e) {
  return e === "indeterminate";
}
function qe(e) {
  return Me(e) ? "indeterminate" : e ? "checked" : "unchecked";
}
function da(e) {
  const t = document.activeElement;
  for (const n of e)
    if (n === t || (n.focus(), document.activeElement !== t)) return;
}
function fa(e, t) {
  return e.map((n, r) => e[(t + r) % e.length]);
}
function pa(e, t, n) {
  const o = t.length > 1 && Array.from(t).every((u) => u === t[0]) ? t[0] : t, s = n ? e.indexOf(n) : -1;
  let i = fa(e, Math.max(s, 0));
  o.length === 1 && (i = i.filter((u) => u !== n));
  const l = i.find(
    (u) => u.toLowerCase().startsWith(o.toLowerCase())
  );
  return l !== n ? l : void 0;
}
function ma(e, t) {
  const { x: n, y: r } = e;
  let o = !1;
  for (let s = 0, i = t.length - 1; s < t.length; i = s++) {
    const c = t[s].x, l = t[s].y, u = t[i].x, v = t[i].y;
    l > r != v > r && n < (u - c) * (r - l) / (v - l) + c && (o = !o);
  }
  return o;
}
function va(e, t) {
  if (!t) return !1;
  const n = { x: e.clientX, y: e.clientY };
  return ma(n, t);
}
function me(e) {
  return (t) => t.pointerType === "mouse" ? e(t) : void 0;
}
var ha = Zt, ga = We, wa = Qt, xa = Jt, Ca = Xe, ba = en, ya = Ne, Ea = nn, Sa = on, Ma = sn, Ra = un, Pa = ln, _a = dn, Na = pn, Ia = mn, Aa = hn, Qe = "DropdownMenu", [Ta, ws] = pt(
  Qe,
  [Vt]
), N = Vt(), [Oa, wn] = Ta(Qe), xn = (e) => {
  const {
    __scopeDropdownMenu: t,
    children: n,
    dir: r,
    open: o,
    defaultOpen: s,
    onOpenChange: i,
    modal: c = !0
  } = e, l = N(t), u = a.useRef(null), [v = !1, d] = Be({
    prop: o,
    defaultProp: s,
    onChange: i
  });
  return /* @__PURE__ */ f.jsx(
    Oa,
    {
      scope: t,
      triggerId: fe(),
      triggerRef: u,
      contentId: fe(),
      open: v,
      onOpenChange: d,
      onOpenToggle: a.useCallback(() => d((m) => !m), [d]),
      modal: c,
      children: /* @__PURE__ */ f.jsx(ha, { ...l, open: v, onOpenChange: d, dir: r, modal: c, children: n })
    }
  );
};
xn.displayName = Qe;
var Cn = "DropdownMenuTrigger", bn = a.forwardRef(
  (e, t) => {
    const { __scopeDropdownMenu: n, disabled: r = !1, ...o } = e, s = wn(Cn, n), i = N(n);
    return /* @__PURE__ */ f.jsx(ga, { asChild: !0, ...i, children: /* @__PURE__ */ f.jsx(
      A.button,
      {
        type: "button",
        id: s.triggerId,
        "aria-haspopup": "menu",
        "aria-expanded": s.open,
        "aria-controls": s.open ? s.contentId : void 0,
        "data-state": s.open ? "open" : "closed",
        "data-disabled": r ? "" : void 0,
        disabled: r,
        ...o,
        ref: Re(t, s.triggerRef),
        onPointerDown: M(e.onPointerDown, (c) => {
          !r && c.button === 0 && c.ctrlKey === !1 && (s.onOpenToggle(), s.open || c.preventDefault());
        }),
        onKeyDown: M(e.onKeyDown, (c) => {
          r || (["Enter", " "].includes(c.key) && s.onOpenToggle(), c.key === "ArrowDown" && s.onOpenChange(!0), ["Enter", " ", "ArrowDown"].includes(c.key) && c.preventDefault());
        })
      }
    ) });
  }
);
bn.displayName = Cn;
var Da = "DropdownMenuPortal", yn = (e) => {
  const { __scopeDropdownMenu: t, ...n } = e, r = N(t);
  return /* @__PURE__ */ f.jsx(wa, { ...r, ...n });
};
yn.displayName = Da;
var En = "DropdownMenuContent", Sn = a.forwardRef(
  (e, t) => {
    const { __scopeDropdownMenu: n, ...r } = e, o = wn(En, n), s = N(n), i = a.useRef(!1);
    return /* @__PURE__ */ f.jsx(
      xa,
      {
        id: o.contentId,
        "aria-labelledby": o.triggerId,
        ...s,
        ...r,
        ref: t,
        onCloseAutoFocus: M(e.onCloseAutoFocus, (c) => {
          var l;
          i.current || (l = o.triggerRef.current) == null || l.focus(), i.current = !1, c.preventDefault();
        }),
        onInteractOutside: M(e.onInteractOutside, (c) => {
          const l = c.detail.originalEvent, u = l.button === 0 && l.ctrlKey === !0, v = l.button === 2 || u;
          (!o.modal || v) && (i.current = !0);
        }),
        style: {
          ...e.style,
          "--radix-dropdown-menu-content-transform-origin": "var(--radix-popper-transform-origin)",
          "--radix-dropdown-menu-content-available-width": "var(--radix-popper-available-width)",
          "--radix-dropdown-menu-content-available-height": "var(--radix-popper-available-height)",
          "--radix-dropdown-menu-trigger-width": "var(--radix-popper-anchor-width)",
          "--radix-dropdown-menu-trigger-height": "var(--radix-popper-anchor-height)"
        }
      }
    );
  }
);
Sn.displayName = En;
var ja = "DropdownMenuGroup", Mn = a.forwardRef(
  (e, t) => {
    const { __scopeDropdownMenu: n, ...r } = e, o = N(n);
    return /* @__PURE__ */ f.jsx(Ca, { ...o, ...r, ref: t });
  }
);
Mn.displayName = ja;
var Fa = "DropdownMenuLabel", Rn = a.forwardRef(
  (e, t) => {
    const { __scopeDropdownMenu: n, ...r } = e, o = N(n);
    return /* @__PURE__ */ f.jsx(ba, { ...o, ...r, ref: t });
  }
);
Rn.displayName = Fa;
var La = "DropdownMenuItem", Pn = a.forwardRef(
  (e, t) => {
    const { __scopeDropdownMenu: n, ...r } = e, o = N(n);
    return /* @__PURE__ */ f.jsx(ya, { ...o, ...r, ref: t });
  }
);
Pn.displayName = La;
var ka = "DropdownMenuCheckboxItem", _n = a.forwardRef((e, t) => {
  const { __scopeDropdownMenu: n, ...r } = e, o = N(n);
  return /* @__PURE__ */ f.jsx(Ea, { ...o, ...r, ref: t });
});
_n.displayName = ka;
var $a = "DropdownMenuRadioGroup", Nn = a.forwardRef((e, t) => {
  const { __scopeDropdownMenu: n, ...r } = e, o = N(n);
  return /* @__PURE__ */ f.jsx(Sa, { ...o, ...r, ref: t });
});
Nn.displayName = $a;
var Ua = "DropdownMenuRadioItem", In = a.forwardRef((e, t) => {
  const { __scopeDropdownMenu: n, ...r } = e, o = N(n);
  return /* @__PURE__ */ f.jsx(Ma, { ...o, ...r, ref: t });
});
In.displayName = Ua;
var Ba = "DropdownMenuItemIndicator", An = a.forwardRef((e, t) => {
  const { __scopeDropdownMenu: n, ...r } = e, o = N(n);
  return /* @__PURE__ */ f.jsx(Ra, { ...o, ...r, ref: t });
});
An.displayName = Ba;
var Ga = "DropdownMenuSeparator", Tn = a.forwardRef((e, t) => {
  const { __scopeDropdownMenu: n, ...r } = e, o = N(n);
  return /* @__PURE__ */ f.jsx(Pa, { ...o, ...r, ref: t });
});
Tn.displayName = Ga;
var Ka = "DropdownMenuArrow", On = a.forwardRef(
  (e, t) => {
    const { __scopeDropdownMenu: n, ...r } = e, o = N(n);
    return /* @__PURE__ */ f.jsx(_a, { ...o, ...r, ref: t });
  }
);
On.displayName = Ka;
var Wa = (e) => {
  const { __scopeDropdownMenu: t, children: n, open: r, onOpenChange: o, defaultOpen: s } = e, i = N(t), [c = !1, l] = Be({
    prop: r,
    defaultProp: s,
    onChange: o
  });
  return /* @__PURE__ */ f.jsx(Na, { ...i, open: c, onOpenChange: l, children: n });
}, Ha = "DropdownMenuSubTrigger", Dn = a.forwardRef((e, t) => {
  const { __scopeDropdownMenu: n, ...r } = e, o = N(n);
  return /* @__PURE__ */ f.jsx(Ia, { ...o, ...r, ref: t });
});
Dn.displayName = Ha;
var Ya = "DropdownMenuSubContent", jn = a.forwardRef((e, t) => {
  const { __scopeDropdownMenu: n, ...r } = e, o = N(n);
  return /* @__PURE__ */ f.jsx(
    Aa,
    {
      ...o,
      ...r,
      ref: t,
      style: {
        ...e.style,
        "--radix-dropdown-menu-content-transform-origin": "var(--radix-popper-transform-origin)",
        "--radix-dropdown-menu-content-available-width": "var(--radix-popper-available-width)",
        "--radix-dropdown-menu-content-available-height": "var(--radix-popper-available-height)",
        "--radix-dropdown-menu-trigger-width": "var(--radix-popper-anchor-width)",
        "--radix-dropdown-menu-trigger-height": "var(--radix-popper-anchor-height)"
      }
    }
  );
});
jn.displayName = Ya;
var Va = xn, Xa = bn, za = yn, Za = Sn, qa = Mn, Qa = Rn, Ja = Pn, es = _n, ts = Nn, ns = In, Fn = An, rs = Tn, os = On, as = Wa, ss = Dn, cs = jn;
const Ln = os;
Ln.displayName = "Arrow";
const kn = a.forwardRef(({ className: e, children: t, checked: n, ...r }, o) => /* @__PURE__ */ f.jsxs(
  es,
  {
    ref: o,
    className: G(
      "relative flex cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
      e
    ),
    checked: n,
    ...r,
    children: [
      /* @__PURE__ */ f.jsx("span", { className: "absolute left-2 flex h-3.5 w-3.5 items-center justify-center", children: /* @__PURE__ */ f.jsx(Fn, { children: /* @__PURE__ */ f.jsx(Ue, { name: "Check" }) }) }),
      t
    ]
  }
));
kn.displayName = "CheckboxItem";
const $n = a.forwardRef(({ className: e, sideOffset: t = 4, withPortal: n, ...r }, o) => {
  const s = /* @__PURE__ */ f.jsx(
    Za,
    {
      ref: o,
      sideOffset: t,
      className: G(
        "DropdownMenuContent z-dialog [data-disabled]:hidden p-4 gap-3 flex flex-col overflow-hidden overflow-y-auto scrollbar-stable rounded-md border border-gray-neutral-80 bg-popover text-popover-foreground shadow-md animate-in data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
        e
      ),
      ...r
    }
  );
  return n ? /* @__PURE__ */ f.jsx(us.Portal, { children: s }) : s;
});
$n.displayName = "Content";
const Un = qa;
Un.displayName = "Group";
const Bn = a.forwardRef(({ className: e, inset: t, ...n }, r) => /* @__PURE__ */ f.jsx(
  Ja,
  {
    ref: r,
    className: G(
      "relative flex cursor-pointer select-none items-center rounded-sm px-2 data-[selected=true]:bg-gray-neutral-30 py-1.5 text-sm outline-none transition-colors focus:bg-gray-neutral-30 focus:text-accent-foreground hover:bg-gray-neutral-30 min-h-9 data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
      t && "pl-8",
      e
    ),
    ...n
  }
));
Bn.displayName = "Item";
const Gn = a.forwardRef(({ className: e, inset: t, ...n }, r) => /* @__PURE__ */ f.jsx(
  Qa,
  {
    ref: r,
    className: G(
      "px-2 py-1.5 text-sm font-semibold",
      t && "pl-8",
      e
    ),
    ...n
  }
));
Gn.displayName = "Label";
const Kn = za;
Kn.displayName = "Portal";
const Wn = ts;
Wn.displayName = "RadioGroup";
const Hn = a.forwardRef(({ className: e, children: t, ...n }, r) => /* @__PURE__ */ f.jsxs(
  ns,
  {
    ref: r,
    className: G(
      "relative flex cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
      e
    ),
    ...n,
    children: [
      /* @__PURE__ */ f.jsx("span", { className: "absolute left-2 flex h-3.5 w-3.5 items-center justify-center", children: /* @__PURE__ */ f.jsx(Fn, { children: /* @__PURE__ */ f.jsx(Ue, { name: "Circle" }) }) }),
      t
    ]
  }
));
Hn.displayName = "RadioItem";
const Yn = a.forwardRef(({ className: e, ...t }, n) => /* @__PURE__ */ f.jsx(
  rs,
  {
    ref: n,
    className: G("-mx-1 h-px bg-gray-neutral-80", e),
    ...t
  }
));
Yn.displayName = "Separator";
const Vn = ({
  className: e,
  ...t
}) => /* @__PURE__ */ f.jsx(
  "span",
  {
    className: G("ml-auto text-xs tracking-widest opacity-60", e),
    ...t
  }
);
Vn.displayName = "Shortcut";
const Xn = as;
Xn.displayName = "Sub";
const zn = a.forwardRef(({ className: e, ...t }, n) => /* @__PURE__ */ f.jsx(
  cs,
  {
    ref: n,
    className: G(
      "z-tooltip min-w-[8rem] overflow-hidden rounded-md border bg-popover p-1 text-popover-foreground shadow-md animate-in data-[side=bottom]:slide-in-from-top-1 data-[side=left]:slide-in-from-right-1 data-[side=right]:slide-in-from-left-1 data-[side=top]:slide-in-from-bottom-1",
      e
    ),
    ...t
  }
));
zn.displayName = "SubContent";
const Zn = a.forwardRef(({ className: e, inset: t, children: n, ...r }, o) => /* @__PURE__ */ f.jsx(
  ss,
  {
    ref: o,
    className: G(
      "flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none focus:bg-gray-neutral-30 data-[state=open]:bg-gray-neutral-30 data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
      t && "pl-8",
      e
    ),
    ...r,
    children: /* @__PURE__ */ f.jsxs("div", { className: G("flex items-center justify-between w-full", e), children: [
      n,
      /* @__PURE__ */ f.jsx(Ue, { name: "NavArrowRight", width: 24, height: 24 })
    ] })
  }
));
Zn.displayName = "SubTrigger";
const qn = Xa;
qn.displayName = "Trigger";
const is = Va, us = Object.assign(is, {
  Arrow: Ln,
  Trigger: qn,
  Group: Un,
  Portal: Kn,
  Sub: Xn,
  RadioGroup: Wn,
  SubTrigger: Zn,
  SubContent: zn,
  Content: $n,
  Item: Bn,
  CheckboxItem: kn,
  RadioItem: Hn,
  Label: Gn,
  Separator: Yn,
  Shortcut: Vn
});
export {
  us as D
};
